<template>
  <div>
    <div class="search">
      <el-row :gutter="24">
        <el-col :span="5">
          <div class="search_input">
            <el-date-picker
              v-model="download.startTime"
              type="date"
              placeholder="开始时间"
            >
            </el-date-picker>
          </div>
        </el-col>
        <el-col :span="5">
          <div class="search_input">
            <el-date-picker
              v-model="download.endTime"
              type="date"
              placeholder="结束时间"
            >
            </el-date-picker>
          </div>
        </el-col>
        <el-col :span="4">
          <div class="search_input">
            <el-select v-model="download.isGet" placeholder="请选择领取状态">
              <el-option label="全部" value="null"> </el-option>
              <el-option label="未领取" value="0"> </el-option>
              <el-option label="已领取" value="1"> </el-option>
            </el-select>
          </div>
        </el-col>
        <el-col :span="1">
          <div class="search_input">
            <el-button
              class="btn"
              @click="handleDownload"
              icon="el-icon-download"
              type="primary"
              >下载</el-button
            >
          </div>
        </el-col>
      </el-row>
    </div>
    <div class="table">
      <el-table :data="tableData" style="width: 100%">
        <el-table-column type="index" align="center" label="序号">
        </el-table-column>
        <el-table-column prop="name" align="center" label="商品名称">
        </el-table-column>
        <el-table-column prop="integralGoods" align="center" label="商品积分">
        </el-table-column>
        <el-table-column prop="personName" align="center" label="兑换人">
        </el-table-column>
        <el-table-column prop="phone" align="center" label="电话号码">
        </el-table-column>
        <el-table-column prop="createTime" align="center" label="创建时间">
        </el-table-column>
        <el-table-column fixed="right" align="center" label="操作">
          <template slot-scope="scope">
            <el-button
              size="mini"
              :disabled="scope.row.isGet == 0 ? false : true"
              type="text"
              @click="handleGet(scope.row.id)"
              >领取</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <div class="pagination" v-if="page">
        <el-pagination
          @current-change="handleCurrentChange"
          :page-size="page.pageSize"
          layout="total, prev, pager, next"
          :total="page.total"
        >
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import MoTable from "../../../components/MoTable/MoTable";
export default {
  name: "goodsRecord",
  components: {
    MoTable,
  },
  data() {
    return {
      download: {
        isGet: "",
        startTime: "",
        endTime: "",
      },
      tableData: [],
      value1: "",
      page: {
        pageNum: 1,
        pageSize: 10,
        total: 0,
      },
    };
  },
  mounted() {
    this.getList();
  },
  methods: {
    async handleDownload() {
      const res = await this.$dow("/poi/getGoodsPoi", this.download);
      console.log(res);
      // console.log(this.download);
      let url = window.URL.createObjectURL(
        new Blob([res], { type: "application/vnd.ms-excel;charset=UTF-8" })
      );
      let link = document.createElement("a");
      link.style.display = "none";
      link.href = url;
      link.setAttribute("download", "兑换记录表");
      document.body.appendChild(link);
      link.click();
    },
    async getList() {
      const res = await this.$get("/goods/getExchangeGoodsList", this.page);
      //   console.log(res);
      if (res.flag) {
        this.tableData = res.data.rows;
        this.page.total = res.data.total;
      }
    },
    // 领取
    handleGet(id) {
      this.$confirm("确认领取成功？")
        .then(async (_) => {
          const res = await this.$put(`/goods/changeExchangeStatus/${id}`);
          // console.log(res);
          if (res.flag) {
            this.$message.success(res.message);
            this.getList();
          } else {
            this.$message.error(res.message);
          }
        })
        .catch((_) => {});
    },
    handleCurrentChange(n) {
      this.page.pageNum = n;
      this.getList();
    },
  },
};
</script>

<style lang="less" scoped>
.table {
  border-bottom-right-radius: 16px;
  border-bottom-left-radius: 16px;
  background-color: #fff;
  overflow-y: auto;
  border-radius: 16px;
  .pagination {
    padding: 30px 0;
    display: flex;
    justify-content: flex-end;
    /deep/ .el-pagination {
      .el-pagination__sizes {
        margin-right: 100px;
      }
      .el-pagination__jump {
        margin-right: 100px;
      }
    }
  }
}
.search {
    width: 100%;
    height: 80px;
    background: #ffffff;
    border-radius: 16px;
    margin-top: 16px;
    .search_input {
      height: 80px;
      display: flex;
      align-items: center;
      padding: 0 10px 0 30px;
      .btn {
        width: 110px;
        height: 40px;
      }
    }
  }
</style>